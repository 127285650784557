<template>
  <div class="col-sm-6 col-lg-4">
    <div class="card">
      <div class="px-1 py-1">
        <div class="d-flex justify-content-end">
          <span @click="navigate(statisticsItem[item_key].url)" class="cursor-pointer">See All</span>
        </div>
      </div>
      <hr style="margin: 0">
      <div @click="navigate(statisticsItem[item_key].url)"
           class="card-body d-flex justify-content-between align-items-center cursor-pointer">
        <div class="truncate">
          <h2 style="font-size: 24px" class="mb-25 font-weight-bolder text-left">
            {{ commaFormat(statisticsItem[item_key].value) }} </h2>
          <p style="font-size: 14px" class="qcont mb-0 text-left"> {{ statisticsItem[item_key].name }} </p>
        </div>
        <span class="b-avatar badge-light-primary rounded-circle" style="width: 45px; height: 45px;">
            <span class="b-avatar-custom">
                <img width="35" :src="`/app-assets/images/icons/dashboard/${statisticsItem[item_key].image}.png`"
                     alt="">
            </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {useRoute, useRouter} from "vue-router";
import useDate from "@/services/utils/day";
import {ref} from "vue";

const {
  firstDateOfMonth,
  lastDateOfMonth
} = useDate()
const router = useRouter()
const route = useRoute()
const {commaFormat} = figureFormatter()

const item_key = ref('budget_balance');
defineProps({
  statisticsItem: Object,
  page: Object
})

function navigate(url) {
  router.push({name: url, params: route.params, query: {start: firstDateOfMonth, end: lastDateOfMonth}})
}
</script>
<style scoped>

</style>
