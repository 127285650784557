<template>
  <div class="card shadow" v-for="submenu in submenus">
    <div class="card-body  p-sm-0">
      <h4 class="card-title mb-0">{{ submenu.name }}</h4>
      <hr>
    </div>
    <div class="card-body px-1">
      <div class="row text-center dashboard-card" style="margin: 5px auto;">
        <template v-for="(page, index) in submenu.pages" :key="index">
          <component
              v-if="page.status == 'active'"
              :is="cards[page.route]"
              :page="page"
              :statisticsItem="statisticsItem"
              @onDateChange="onDateChange"
              :timeRange="timeRange"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  TotalIncome,
  TotalExpense,
  TotalIoe,
  TotalReceipts,
  TotalPayment,
  TotalCashBankBalance,
  TotalReceivables,
  TotalPayables,
  TotalBudgetBalance,
  ProjectDonationChart,
  ProjectExpenseChart,
  ProjectBudgetBalanceChart,
  ReceiptAndPaymentChart,

  // Management Summary
  ProjectBudgetBalanceSummary,
  ProjectCashBankBalanceSummary,
  FD6BudgetBalanceSummary,
  Top5ExpenseCategorySummary,
  Top5ExpenseProjectSummary
} from '@/components/molecule/company/dashboard/components'

const cards = {
  'total-income': TotalIncome,
  'total-expense': TotalExpense,
  'total-ioe': TotalIoe,
  'total-receipts': TotalReceipts,
  'total-payment': TotalPayment,
  'total-cash-bank-balance': TotalCashBankBalance,
  'total-receivables': TotalReceivables,
  'total-payables': TotalPayables,
  'total-budget-balance': TotalBudgetBalance,
  'project-donation-chart': ProjectDonationChart,
  'project-expense-chart': ProjectExpenseChart,
  'project-budget-balance-chart': ProjectBudgetBalanceChart,
  'receipt-and-payment-chart': ReceiptAndPaymentChart,

  // Management Summary
  'project-budget-balance-summary': ProjectBudgetBalanceSummary,
  'project-cash-bank-balance-summary': ProjectCashBankBalanceSummary,
  'fd6-budget-balance-summary': FD6BudgetBalanceSummary,
  'top5-expense-category-summary': Top5ExpenseCategorySummary,
  'top5-expense-project-summary': Top5ExpenseProjectSummary,
};

import Network from "@/services/network";
import {useRoute} from "vue-router";
import {onMounted} from "vue";
import {useCardStatistics} from "@/services/modules/dashboard/cardStatistics";

const network = new Network
defineProps({
  submenus: Array,
})
onMounted(() => {
  getDashboardData()
})

const route = useRoute()
const params = route.params

const onDateChange = (item) => {
  let query = `&${item.key}_date={${item.dateRange}}`
  getDashboardData(query, item.key)
}
defineExpose({
  onDateChange,
});

const {
  timeRange,
  getDashboardData,
  statisticsItem
} = useCardStatistics(params)
</script>

<style scoped>

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 0 solid rgba(34, 41, 47, 0.125);
  border-radius: 15px;
}

.card-body {
  /*padding: 0;*/
  border-radius: 15px 15px 0 0 !important;
}

.card-title {
  background-color: #fff;
  margin: 0;
  padding: 15px 15px;
  color: #7D7D7D;
  border-radius: 15px 15px 0 0;
  /* border-bottom: .5px solid #7D7D7D; */
}

hr {
  margin: 0;
}

/deep/ .dashboard-card .card, /deep/ .dashboard-card .form-control {
  background-color: #ebecf0;
}

/deep/ .dashboard-card .card.height-235 {
  height: 235px;
}

/deep/ .dashboard-card .card .cut-text {
  width: 143px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
