import {includes, replace} from 'lodash'
import Network from '@/services/network'
import {ref} from 'vue'
import useDate from '@/services/utils/day'
import figureFormatter from '@/services/utils/figureFormatter.js'

const {
    firstDateOfQuarter,
    lastDateOfQuarter,
    lastDateOfYear,
    firstDateOfYear,
    firstDateOfLastYear,
    lastDateOfLastYear,
    firstDateOfMonth,
    lastDateOfMonth,
    firstDateOfLastMonth,
    lastDateOfLastMonth
} = useDate()

const network = new Network

export function useCardStatistics(params) {
    const statisticsItem = ref({
        income: {
            key: 'income',
            name: 'Total Income',
            route: 'total-income',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        expense: {
            key: 'expense',
            name: 'Total Expenses',
            route: 'total-expense',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'expense'
        },
        profit_and_loss: {
            key: 'profit_and_loss',
            name: 'Total Income over expense',
            route: 'total-ioe',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'profit_and_loss'
        },
        receipt: {
            key: 'receipt',
            name: 'Total Receipts',
            route: 'total-receipts',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        payment: {
            key: 'payment',
            name: 'Total Payment',
            route: 'total-payment',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        cash_and_bank: {
            key: 'cash_and_bank',
            name: 'Total Cash & Bank Balance',
            route: 'total-cash-bank-balance',
            value: 0,
            url: 'cash-bank-register-dashboard',
            image: 'cash_and_bank'
        },
        receivable: {
            key: 'receivable',
            name: 'Total Receivables',
            route: 'total-receivables',
            value: 0,
            url: 'receivable-register-dashboard',
            image: 'receivable'
        },
        payable: {
            key: 'payable',
            name: 'Total Payables',
            route: 'total-payables',
            value: 0,
            url: 'payable-register-dashboard',
            image: 'payable'
        },
        budget_balance: {
            key: 'budget_balance',
            name: 'Total Budget Balance',
            route: 'total-budget-balance',
            value: 0,
            url: 'project-budget-balance-summary-dashboard',
            image: 'income'
        },
        project_donation_chart: {
            key: 'project_donation_chart',
            name: 'Project Donation Chart',
            route: 'project-donation-chart',
            value: {},
            isFilterable: true,
            dateRange: '',
        },
        project_expense_chart: {
            key: 'project_expense_chart',
            name: 'Project Expense Chart',
            route: 'project-expense-chart',
            value: {},
            isFilterable: true,
            dateRange: '',
        },
        receipt_and_payment_chart: {
            key: 'receipt_and_payment_chart',
            name: 'Receipt & Payment',
            route: 'receipt-and-payment-chart',
            value: [],
            isFilterable: true,
            dateRange: '',
        },
        project_budget_balance_chart: {
            key: 'project_budget_balance_chart',
            name: 'Project Budget Balance Chart',
            route: 'project-budget-balance-chart',
            value: {},
            url: 'project-budget-balance-summary-dashboard',
        },
        project_budget_balance_summary: {
            key: 'project_budget_balance_summary',
            name: 'Project Budget Balance',
            route: 'project-budget-balance-summary',
            value: [],
            url: 'project-budget-balance-summary-dashboard',
        },
        project_cash_bank_balance_summary: {
            key: 'project_cash_bank_balance_summary',
            name: 'Project wise Cash & Bank Balance',
            route: 'project-cash-bank-balance-summary',
            value: [],
            url: 'project-cash-bank-balance-summary-dashboard',
        },
        fd6_budget_balance_summary: {
            key: 'fd6_budget_balance_summary',
            name: 'FD6 Budget Balance',
            route: 'fd6-budget-balance-summary',
            value: [],
            url: 'fd6-budget-balance-summary-dashboard',
        },
        top5_expense_category_summary: {
            key: 'top5_expense_category_summary',
            name: 'Top 5 Expenses by Category',
            route: 'top5-expense-category-summary',
            value: [],
            url: 'expense-category-summary-dashboard',
        },
        top5_expense_project_summary: {
            key: 'top5_expense_project_summary',
            name: 'Top 5 Expense by Project',
            route: 'top5-expense-project-summary',
            value: [],
            url: 'expense-project-summary-dashboard',
        },

    })
    const {commaFormat} = figureFormatter()

    const makeStatistics = (statisticsData, notIncludeArr) => {
        let statistics = []
        Object.keys(statisticsData).forEach((key) => {
            if (!includes(notIncludeArr, key)) {
                statistics.push(
                    {
                        name: replace(key, /[\_]/g, ' '),
                        value: commaFormat(statisticsData[key]),
                        key: key
                    }
                )
            }
        })
        return statistics
    }

    function setValue(payload, key) {
        const {
            cash_and_bank,
            expense,
            income,
            payable,
            receivable,
            profit_and_loss,
            receipt,
            payment,
            budget_balance,

            //Charts
            project_donation_chart,
            project_expense_chart,
            project_budget_balance_chart,
            receipt_and_payment,

            // Management Summary
            project_budget_balance_summary,
            project_cash_bank_balance_summary,
            fd6_budget_balance_summary,
            top5_expense_category_summary,
            top5_expense_project_summary
        } = payload

        if (key.length) {
            statisticsItem.value[key].value = payload[key]
            return false;
        }

        statisticsItem.value.income.value = income
        statisticsItem.value.cash_and_bank.value = cash_and_bank
        statisticsItem.value.expense.value = expense
        statisticsItem.value.payable.value = payable
        statisticsItem.value.receivable.value = receivable
        statisticsItem.value.profit_and_loss.value = profit_and_loss
        statisticsItem.value.receipt.value = receipt
        statisticsItem.value.payment.value = payment
        statisticsItem.value.budget_balance.value = budget_balance

        //Charts
        statisticsItem.value.project_donation_chart.value = project_donation_chart
        statisticsItem.value.project_expense_chart.value = project_expense_chart
        statisticsItem.value.project_budget_balance_chart.value = project_budget_balance_chart
        statisticsItem.value.receipt_and_payment_chart.value = receipt_and_payment

        // Management Summary
        statisticsItem.value.project_budget_balance_summary.value = project_budget_balance_summary
        statisticsItem.value.project_cash_bank_balance_summary.value = project_cash_bank_balance_summary
        statisticsItem.value.fd6_budget_balance_summary.value = fd6_budget_balance_summary
        statisticsItem.value.top5_expense_category_summary.value = top5_expense_category_summary
        statisticsItem.value.top5_expense_project_summary.value = top5_expense_project_summary
    }

    function getDashboardData(query = '', key = '') {
        network.api('get', `/get/dashboard?company_id=${params.companyId}&module_id=${params.moduleId}` + query)
            .then(res => {
                if (res.status) {
                    setValue(res.data, key)
                }
            })
    }

    //`"start":"","end":""`
    const timeRange = [
        {
            name: 'This Month',
            value: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `
        },
        {
            name: 'Last Month',
            value: `
            "start":"${firstDateOfLastMonth}",
            "end":"${lastDateOfLastMonth}"
            `
        },
        {
            name: 'This Quarter',
            value: `
            "start":"${firstDateOfQuarter}",
            "end":"${lastDateOfQuarter}"
            `
        },
        {
            name: 'This Year',
            value: `
            "start":"${firstDateOfYear}",
            "end":"${lastDateOfYear}"
            `
        },
        {
            name: 'Last Year',
            value: `
            "start":"${firstDateOfLastYear}",
            "end":"${lastDateOfLastYear}"
            `
        }

    ]

    return {
        makeStatistics,
        timeRange,
        getDashboardData,
        statisticsItem,
    }
}
