<template>
  <div class="col-md-4">
    <div class="card p-1 height-235">
      <div class="d-flex justify-content-between">
        <span>{{ statisticsItem[item_key].name }}</span>
        <span @click="navigate(statisticsItem[item_key].url)" class="cursor-pointer">See All</span>
      </div>
      <hr>
      <p class="text-center center-position" v-if="statisticsItem[item_key].value?.length == 0">Not data available</p>
      <table v-else class="table table-sm table-striped">
        <thead>
        </thead>
        <tbody>
        <tr v-for="(list, key) in  statisticsItem[item_key].value">
          <th scope="row">{{ ++key }}</th>
          <td class="text-start">
            <div class="cut-text">
              {{ list.name }}
            </div>
          </td>
          <td class="text-end">{{ commaFormat(list.amount) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import useDate from "@/services/utils/day";
import {ref} from "vue";
import figureFormatter from "@/services/utils/figureFormatter";

const {firstDateOfMonth, lastDateOfMonth} = useDate()

const router = useRouter()
const route = useRoute()
const {commaFormat} = figureFormatter()

const item_key = ref('top5_expense_category_summary');

defineProps({
  statisticsItem: Object,
  page: Object
})

function navigate(url) {
  router.push({name: url, params: route.params, query: {start: firstDateOfMonth, end: lastDateOfMonth}})
}

</script>
