<template>
  <div class="col-md-4">
    <div class="card p-1">
      <div class="d-flex justify-content-between">
        <span>{{ statisticsItem[item_key].name }}</span>
        <select @change="$emit('onDateChange', statisticsItem[item_key])"
                v-model="statisticsItem[item_key].dateRange" class="form-control"
                style="padding: 0; border:none; text-align: right; width: 90px">
          <option value="" disabled selected>Select Range</option>
          <option v-for="(range, j) in timeRange" :key="j" :value="range.value">{{ range.name }}</option>
        </select>
      </div>
      <hr>
      <p class="text-center center-position" v-if="statisticsItem[item_key].value?.labels?.length == 0">Not data available</p>
      <PieChart :chartData="statisticsItem[item_key].value"/>
    </div>
  </div>
</template>

<script setup>
import PieChart from '@/components/atom/chart/pieChart'
import {ref} from "vue";

const item_key = ref('project_donation_chart');
defineProps({
  statisticsItem: Object,
  page: Object,
  timeRange: Array,
})
</script>
