<template>
  <div class="col-sm-6 col-lg-4">
    <div class="card">
      <div class="d-flex justify-content-end px-1 py-1 text-right">
        <select @change="$emit('onDateChange', statisticsItem[item_key])"
                v-model="statisticsItem[item_key].dateRange" class="form-control width-150"
                style="padding: 0; border:none; text-align: right">
          <option value="" disabled selected>Select Range</option>
          <option v-for="(range, j) in timeRange" :key="j" :value="range.value">{{ range.name }}</option>
        </select>
      </div>
      <hr style="margin:0">
      <div  @click="goToPage('profit-loss')"
            class="cursor-pointer card-body d-flex justify-content-between align-items-center">
        <div class="truncate">
          <h2 style="font-size: 24px" class="mb-25 font-weight-bolder text-left">
            {{ commaFormat(statisticsItem[item_key].value) }} </h2>
          <p style="font-size: 14px" class="qcont mb-0 text-left"> {{ statisticsItem[item_key].name }} </p>
        </div>
        <span class="b-avatar badge-light-primary rounded-circle" style="width: 45px; height: 45px;">
              <span class="b-avatar-custom">
                  <img width="35" :src="`/app-assets/images/icons/dashboard/${statisticsItem[item_key].image}.png`"
                       alt="">
              </span>
          </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import figureFormatter from "@/services/utils/figureFormatter";
import {ref} from "vue";
import handleRedirectToPage from "@/services/modules/redirectToPage";

const item_key = ref('profit_and_loss');
const {commaFormat} = figureFormatter()
const {goToPage} = handleRedirectToPage()

defineProps({
  statisticsItem: Object,
  page: Object,
  timeRange: Array,
})
</script>

