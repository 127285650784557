<template>
  <div class="col-md-4">
    <div class="card p-1">
      <div class="d-flex justify-content-between">
        <span>{{ statisticsItem[item_key].name }}</span>
        <span @click="navigate(statisticsItem[item_key].url)" class="cursor-pointer">See All</span>
      </div>
      <hr>
      <p class="text-center center-position" v-if="statisticsItem[item_key].value?.labels?.length == 0">Not data available</p>
      <PieChart :chartData="statisticsItem[item_key].value"/>
    </div>
  </div>
</template>

<script setup>
import PieChart from '@/components/atom/chart/pieChart'
import {useRoute, useRouter} from "vue-router";
import useDate from "@/services/utils/day";
import {ref} from "vue";

const {
  firstDateOfMonth,
  lastDateOfMonth
} = useDate()
const router = useRouter()
const route = useRoute()

const item_key = ref('project_budget_balance_chart');
defineProps({
  statisticsItem: Object,
  page: Object
})

function navigate(url) {
  router.push({name: url, params: route.params, query: {start: firstDateOfMonth, end: lastDateOfMonth}})
}

</script>
