<template>
    <div style="min-height: calc(100vh - 158px);">
        <!-- <div v-if="!submenus.length">No item found</div> -->
      <template v-if="isDashboard(selectedSidebar)">
        <DynamicDashboard :submenus="submenus"/>
      </template>
      <div v-else>
        <template v-for="(item, index) in submenus" :key="index">
          <SubmenuCard
              v-if="item.status == 'active'"
              :item="item"
          />
        </template>
      </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

import SubmenuCard from '@/components/molecule/company/SubmenuCard'
import DynamicDashboard from '@/views/page/company/DynamicDashboard.vue'

export default {
    name: 'SubmenuPage',

    components: {
        SubmenuCard,
        DynamicDashboard
    },

    methods: {
      isDashboard(selectedSidebar) {
        return selectedSidebar?.is_dashboard
      }
    },

    computed: {
        ...mapGetters({
            sidebar: 'company/getSidebar'
        }),

        selectedSidebar() {
            return this.sidebar.find(i => i.id == this.$route.params.menuId)
        },

        submenus() {
            return this.selectedSidebar?.submenus ? this.selectedSidebar.submenus : []
        }
    }
}
</script>


