<template>
  <div class="col-md-8">
    <div class="card p-1">
      <div style="height: 23px">
        <span>Receipt & Payment</span>
      </div>
      <BarChart
          :chartData="{
                labels: [
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December'
                ],
                datasets: statisticsItem[item_key].value
              }"
      />
    </div>
  </div>
</template>

<script setup>
import BarChart from '@/components/atom/chart/barChart'
import {ref} from "vue";

const item_key = ref('receipt_and_payment_chart');
defineProps({
  statisticsItem: Object,
  page: Object
})
</script>
