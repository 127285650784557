<template>
    <div class="card shadow">
        <div class="card-body  p-sm-0">
            <!-- <img width="25" :src="item.icon" alt="" class="me-2"> -->
            <h4 class="card-title mb-0">{{ item.name }}</h4>
            <!-- <h6 class="card-subtitle text-muted">Support card subtitle</h6> -->
            <hr>
        </div>
        <!-- <img class="img-fluid" src="../../../app-assets/images/slider/03.jpg" alt="Card image cap"> -->
        <div class="card-body p-sm-0">
            <div class="row text-center" style="margin: 0 auto;">
                <template v-for="(page, index) in item.pages" :key="index">
                    <div v-if="page.status == 'active'" class="col-sm-6 col-md-2 py-2 m-1 pageCard">
                        <!-- <PageCard
                            :page="page"
                        /> -->
                        <component
                            :is="currentComponent"
                            :page="page"
                        ></component>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import PageCard from '@/components/molecule/company/PageCard'
import ProcurePageCard from '@/components/molecule/company/ProcurePageCard'

export default {
    name: 'SubmenuCard',
    components: {
        PageCard,
        ProcurePageCard
    },
    props: {
        item: {
            required: true
        }
    },
    computed: {
        moduleId() {
            return this.$route.params.moduleId
        },
        currentComponent() {
            return this.moduleId == 1 ? 'PageCard' : 'ProcurePageCard'
        }
    }
}
</script>

<style scoped>

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid rgba(34, 41, 47, 0.125);
        border-radius: 15px;
    }
    .card-body{
        padding: 0;
        border-radius: 15px 15px 0 0 !important;
    }
    .card-title{
        background-color: #fff;
        margin: 0;
        padding:15px 15px;
        color: #7D7D7D;
        border-radius: 15px 15px 0 0;
        /* border-bottom: .5px solid #7D7D7D; */
    }
      .pageCard{
        padding: 10px;
        margin: 10px !important;
        box-sizing: border-box;
        border-radius: 10px;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 10px;
        margin: 10px !important;
        background-color: #DBE7F2;
        box-sizing: border-box !important;
        cursor: pointer;

    }
    .pageCard p{
        display: -webkit-box;
        width: 130px;
        height: 40px;
        color: #1B73E6;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.625;
    }
    hr{
        margin: 0;
    }
    @media screen and (max-width: 400px){
        .pageCard{
            width: 50%;
        }
         .pageCard{
        padding: 0px;
        margin: 0px !important;
        /* background-color: #DBE7F2; */
        box-sizing: border-box !important;

    }
    .pageCard:hover{
        padding: 0px;
        margin: 0px !important;
        background-color: #DBE7F2;
        border-radius: 10px;
        box-sizing: border-box !important;
    }
    /* .pageCard:hover>.avatar {
        border: 100px solid red;
    } */
    }

</style>
